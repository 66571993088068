import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';
import '../../extended-styles.css';
import TextTruncate from 'react-text-truncate';

const GroupDetail = ({ history, match }) => {
  const firebaseContext = useContext(FirebaseContext);
  const {
    groups,
    uploadImage,
    imageUploads,
    updateTick,
    loadImagesByGroupID,
    groupImages,
    deleteImage,
    clearGroupImages,
    unsubscribeFromGroupImages,
    updateGroup,
    submitGroup,
    user
  } = firebaseContext;

  const [triggerUpload, setTriggerUpload] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [groupNote, setGroupNote] = useState('');
  const [fields, setFields] = useState({
    property_name: '',
    group_name: '',
    note: '',
    show_submitted: false
  });
  const [saveNote, setSaveNote] = useState(false);
  const [imagesState, setImagesState] = useState({
    photos: [],
    selectedImg: null,
    selectedBlob: null,
    filename: ''
  });

  useEffect(() => {
    let selectedGroup = groups.filter(
      (group) => group.id === match.params.groupid
    );
    const { property_name, group_name, note } = selectedGroup[0];
    setSelectedGroup(selectedGroup[0]);
    loadImagesByGroupID(selectedGroup[0].id, selectedGroup[0].company_id);
    // setGroupNote(selectedGroup[0].note);
    setFields({ property_name, group_name, note });

    if (triggerUpload) {
      uploadImages();
      setTriggerUpload(false);
    }

    return function cleanup() {
      // clearGroupImages(selectedGroup[0].id);
      unsubscribeFromGroupImages();
    };
  }, [imagesState.photos, updateTick]);

  const translatePhotos = (files) => {
    return Object.entries(files).map((file) => {
      return file[1];
    });
  };

  const onHandleGroupNoteUpdate = (e) => {
    setGroupNote(e.target.value); //handles local state text area controlled field
    setSaveNote(true);
  };

  const handleFieldOnChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
    setSaveNote(true);
  };

  const onHandleUpdate = (e) => {
    // console.log(Object.values(e.target.files));
    if (e.target.files.length === 0) return;

    let path = URL.createObjectURL(e.target.files[0]);
    setImagesState({
      ...imagesState,
      photos: translatePhotos(e.target.files),
      selectedBlob: e.target.files[0],
      selectedImg: path,
      filename: path.split(/(\\|\/)/g).pop()
    });
    setTriggerUpload(true);
  };

  const uploadImages = () => {
    if (imagesState.photos.length > 0) {
      imagesState.photos.map((image) => {
        uploadImage(selectedGroup.id, image, selectedGroup.company_id);
        // console.log(image.name);
      });
    }
  };

  const handleDeleteImage = (e) => {
    e.preventDefault();
    deleteImage(e.target.id, selectedGroup.company_id);
  };

  const openImageDetail = (e) => {
    let imageId = e.target.id;
    history.push(`/imagedetail/${imageId}`);
  };

  const handleSaveData = () => {
    selectedGroup.note = fields.note || '';
    selectedGroup.property_name = fields.property_name;
    selectedGroup.group_name = fields.group_name;
    updateGroup(selectedGroup);
    setSaveNote(false);
  };

  const handleSubmitGroup = () => {
    const submitRecord = {};
    submitRecord.uid = selectedGroup.uid;
    submitRecord.group_id = selectedGroup.id;
    submitRecord.company_id = selectedGroup.company_id;
    submitGroup(submitRecord);
    history.push(`/userdashboard`);
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row" style={{ marginTop: '15px' }}>
          <label htmlFor="group_name">Group (Work Order Number):</label>
          <input
            type="text"
            value={fields.group_name}
            name="group_name"
            onChange={handleFieldOnChange}
          />
        </div>
        <div className="row">
          <label htmlFor="property_name">Property Name: </label>
          <input
            name="property_name"
            type="text"
            value={fields.property_name}
            onChange={handleFieldOnChange}
          />
        </div>
        {/* <label htmlFor="note hide">Note:</label> */}
        <textarea
          name="note"
          id="note"
          cols="30"
          rows="50"
          placeholder="Enter Note Here"
          className="text-box general-font hide"
          value={fields.note}
          onChange={handleFieldOnChange}></textarea>
        {saveNote ? <SaveBtn saveNote={handleSaveData} /> : null}
        <div className="section">
          <div className="row">
            <form action="#">
              <div className="file-field btn indigo col s12 ">
                <span>Add Image</span>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={onHandleUpdate}
                />
              </div>
            </form>
            {groupImages !== null && groupImages.length > 0 ? (
              <SubmitGroupBtn handleSubmitGroup={handleSubmitGroup} />
            ) : null}
          </div>
        </div>
        <div className="section">
          {groupImages !== null && groupImages.length > 0 ? (
            <GroupImages
              groupImages={groupImages}
              deleteImg={handleDeleteImage}
              openImage={openImageDetail}
            />
          ) : (
            <h5>No Images</h5>
          )}
        </div>
        <div className="row">
          <Uploads imageUploads={imageUploads} />
        </div>
      </div>
    </Fragment>
  );
};

const SubmitGroupBtn = ({ handleSubmitGroup }) => {
  return (
    <button
      className="btn col s12"
      style={{ marginTop: '10px' }}
      onClick={handleSubmitGroup}>
      Submit Group
    </button>
  );
};
const SaveBtn = ({ saveNote }) => {
  return (
    <div
      className="file-field btn col s12 width-100 "
      onClick={saveNote}
      style={{ marginBottom: 5 }}>
      Save Changes
    </div>
  );
};

const UploadProgress = ({ uploadTasks }) => {
  const tasks = uploadTasks.map((obj) => (
    <div key={obj.file_name} className="collection-item avatar">
      <img src={obj.downloadURL} alt="" className="square" />
      <span className="title width-90">
        Uploading:
        <TextTruncate line={1} truncateText="..." text={obj.file_name} />
        {obj.progress} Completed
      </span>

      <div className="progress">
        <div
          className="determinate valign-wrapper"
          style={{ width: obj.progress, height: 10 }}></div>
      </div>
    </div>
  ));

  return (
    <div className="collection" style={{ overflow: 'scroll' }}>
      {tasks}
    </div>
  );
};

const Uploads = ({ imageUploads }) => {
  const uploads =
    imageUploads.length > 0 ? (
      <Fragment>
        <h5>Uploading</h5>
        <UploadProgress uploadTasks={imageUploads} />
      </Fragment>
    ) : null;

  return <Fragment>{uploads}</Fragment>;
};
const GroupImages = ({ groupImages, deleteImg, openImage }) => {
  const images = groupImages.map((image) => (
    <div key={image.id} id={image.id} className="collection-item avatar">
      <div className="width-85">
        <img
          src={image.downloadURL}
          id={image.id}
          alt=""
          onClick={openImage}
          className="square"
        />
        <div className="title">
          <TextTruncate line={1} truncateText="..." text={image.file_name} />
        </div>
        <div className="title note-padding">
          <span className="note-text"> {image.note}</span>
        </div>
        <a href="#" className="secondary-content" onClick={deleteImg}>
          <i className="medium material-icons indigo-text" id={image.id}>
            delete_forever
          </i>{' '}
        </a>
      </div>
    </div>
  ));

  return (
    <div className="collection" style={{ overflow: 'scroll', maxHeight: 300 }}>
      {images}
    </div>
  );
};

export default GroupDetail;
