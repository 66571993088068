import React, { useReducer, useEffect } from 'react';
import FirebaseContext from './firebaseContext';
import firebaseReducer from './firebaseReducer';
import app, { firebase } from './firebaseConfig';
import uploadIMG from '../../images/photoUpload.png';
import Compressor from 'compressorjs';
import { format } from 'date-fns';

import {
  AUTH_USER,
  AUTH_ERROR,
  CLEAR_ERRORS,
  CLEAR_GROUP_IMAGES,
  GET_USER,
  GET_USER_FAIL,
  LOAD_COMPANY,
  LOAD_COMPANY_FAILED,
  LOAD_USERS,
  LOAD_GROUPS,
  LOAD_USERS_FAIL,
  LOAD_GROUP_IMAGES,
  LOAD_INVITED_USERS,
  LOAD_INVITED_USERS_FAILED,
  SET_LOADING,
  SIGN_OUT,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_UPDATE,
  UPLOAD_IMAGE_COMPLETE,
  GROUP_FILTER
} from '../types';

const FirebaseState = (props) => {
  const initialState = {
    authUser: null,
    isAuthenticated: false,
    isAccountManager: false,
    user: null,
    users: null,
    error: '',
    groups: null,
    imageUploads: [],
    updateTick: 0,
    currentGroupId: null,
    groupImages: [],
    loading: true,
    company_name: '',
    company_id: '',
    invitedUsers: null,
    submittedGroupFilter: false
  };
  const [state, dispatch] = useReducer(firebaseReducer, initialState);

  useEffect(() => {
    if (!state.authUser) {
      authUser();
    }
    if (state.isAuthenticated && !state.loading) {
      loadGroupsByUserID();
      dispatch({ type: SET_LOADING, payload: false });
    }
  }, [state.isAuthenticated, state.authUser]);

  const registerCompany = async (registerData) => {
    let newCompany = {};
    newCompany.company_name = registerData.company_name;
    let companyID = await createCompany(newCompany);
    registerData.company_id = companyID;
    let userID = await createUser(registerData);
  };

  const createCompany = async (newCompany) => {
    try {
      const updates = {};
      const company = await app.db.ref('companies');
      let companyID = await company.push().key;
      newCompany.created_date = format(new Date(), 'MM/dd/yyyy');
      updates[companyID] = newCompany;
      company.update(updates);
      return companyID;
    } catch (err) {
      console.log(err);
    }
  };

  const loadCompany = (companyID) => {
    app.db
      .ref(`companies/${companyID}`)
      .once('value')
      .then((snapshot) => {
        if (snapshot.exists()) {
          let company = snapshot.val();
          company.company_id = companyID;
          dispatch({ type: LOAD_COMPANY, payload: company });
        } else {
          dispatch({ type: LOAD_COMPANY_FAILED });
        }
      });
  };
  const authUser = async () => {
    dispatch({ type: SET_LOADING, payload: true });
    app.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        getUser(authUser.uid)
          .then((user) => {
            dispatch({ type: GET_USER, payload: user });
          })
          .then(() => {
            dispatch({ type: SET_LOADING, payload: false });
            dispatch({ type: AUTH_USER, payload: authUser });
          });
      } else {
        dispatch({ type: SIGN_OUT });
      }
    });
  };

  const authenticateUser = async (email, password) => {
    try {
      const authUser = await app
        .auth()
        .signInWithEmailAndPassword(email, password);
      let user = await getUser(authUser.user.uid);
      dispatch({ type: GET_USER, payload: user });
    } catch (error) {
      dispatch({ type: AUTH_ERROR, payload: error.code });
      console.log('ERROR: ' + state.error);
    }
  };

  const resetPassword = async (email) => {
    return app.auth().sendPasswordResetEmail(email);
  };
  const createUser = async ({
    first_name,
    last_name,
    email,
    password1,
    isAccountOwner,
    isAccountManager,
    company_id,
    invite_id
  }) => {
    let authUser = {};
    try {
      authUser = await app
        .auth()
        .createUserWithEmailAndPassword(email, password1);
    } catch (err) {
      console.log(err);
    }

    try {
      if (authUser != null) {
        await app.db.ref(`users/${authUser.user.uid}`).set({
          first_name,
          last_name,
          email,
          isAccountOwner,
          company_id
        });
        dispatch({ type: AUTH_USER, authUser });
        setInviteFlag(company_id, invite_id, true);
        return authUser.user.uid;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUser = (userID) => {
    return new Promise((resolve, reject) => {
      app.db
        .ref(`users/${userID}`)
        .once('value')
        .then((snapshot) => {
          if (snapshot.exists) {
            const user = snapshot.val();
            resolve(user);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log('ERROR: ' + error);
          dispatch({ type: GET_USER_FAIL });
        });
    });
  };

  const loadUsers = async (companyID) => {
    console.log('Loading Users');
    let users = {};
    try {
      let ref = await app.db.ref('users');
      ref
        .orderByChild('company_id')
        .equalTo(companyID)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            users = Object.entries(snapshot.val()).map((item) => {
              return { id: item[0], ...item[1] };
            });
            dispatch({ type: LOAD_USERS, payload: users });
          }
        });
    } catch (error) {
      dispatch({ type: LOAD_USERS_FAIL, payload: error });
    }
  };
  const unsubscribeFromGroupImages = (groupId) => {
    app.db.ref(`groups/${groupId}/images`).off();
  };
  const signOut = () => {
    app.auth().signOut();
    app.db.ref('groups').off('value');
    dispatch({ type: SIGN_OUT });
  };

  const passwordUpdate = async (password) => {
    //update password stub
  };

  const setLoading = () => dispatch({ type: SET_LOADING });

  const createGroup = async (uid, group, company_id) => {
    const updates = {};
    try {
      const newGroup = await app.db.ref(`groups/${company_id}`);
      let groupID = newGroup.push().key;
      group.uid = uid;
      group.company_id = company_id;
      group.submitted = false;

      updates[groupID] = group;
      newGroup.update(updates);
    } catch (err) {
      console.log(err);
    }
  };

  const updateGroup = async (selectedGroup) => {
    const updates = {};
    const groupId = selectedGroup.id;
    updates[`groups/${selectedGroup.company_id}/${groupId}/note`] =
      selectedGroup.note;
    updates[`groups/${selectedGroup.company_id}/${groupId}/group_name`] =
      selectedGroup.group_name;
    updates[`groups/${selectedGroup.company_id}/${groupId}/property_name`] =
      selectedGroup.property_name;
    try {
      app.db.ref().update(updates);
    } catch (error) {
      console.log(error);
    }
  };

  const updateGroupSubmitted = async (company_id, group_id) => {
    const updates = {};
    updates[`groups/${company_id}/${group_id}/submitted`] = true;
    updates[`groups/${company_id}/${group_id}/submitted_timestamp`] =
      Date.now();

    try {
      app.db.ref().update(updates);
    } catch (error) {
      console.log(error);
    }
  };

  const submitGroup = async ({ company_id, uid, group_id }) => {
    //this function is used to signal that the group is complete
    //create a submit group record
    //flag the group as submitted
    const updates = {};
    let submitEvent = {};
    try {
      const newSubmitEvent = await app.db.ref(
        `group_submit_events/${company_id}`
      );
      let submitID = newSubmitEvent.push().key;
      submitEvent = { uid, group_id, processed: false };

      updates[submitID] = submitEvent;
      let retVal = await newSubmitEvent.update(updates);
      updateGroupSubmitted(company_id, group_id);

      return retVal;
    } catch (err) {
      console.log(err);
    }
  };

  const loadGroupsByUserID = async () => {
    //stub to load groups that belong to a user
    console.log('loading groups ');
    if (state.authUser == null) return;

    const { uid } = state.authUser;
    let tmpGroups = [];
    try {
      let ref = await app.db.ref(`groups/${state.user.company_id}`);
      ref
        .orderByChild('uid')
        .equalTo(uid)
        .limitToLast(100)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            tmpGroups = Object.entries(snapshot.val())
              .map((item) => {
                return { id: item[0], ...item[1] };
              })
              .filter((item) => {
                return item.submitted === state.submittedGroupFilter;
              })
              .sort((a, b) => b.submitted_timestamp - a.submitted_timestamp);
            dispatch({ type: LOAD_GROUPS, payload: tmpGroups });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const setSubmittedGroupFilter = (isSubmitted) => {
    dispatch({ type: GROUP_FILTER, payload: isSubmitted });
  };

  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  const createImage = async (image) => {
    const updates = {};
    try {
      const newImage = await app.db
        .ref()
        .child(`groups/${image.company_id}/${image.groupId}/images`);
      let imageID = newImage.push().key;

      updates[imageID] = image;
      newImage.update(updates);
    } catch (err) {
      console.log(err);
    }
  };

  const clearGroupImages = (groupId) => {
    unsubscribeFromGroupImages(groupId);
    dispatch({ type: CLEAR_GROUP_IMAGES });
  };

  const deleteImage = async (imageID, company_id) => {
    //deletes the firebase database image record
    let image = state.groupImages.filter((img) => img.id === imageID);

    if (image) {
      //if there is an image then delete the image from storage

      if (
        deleteStorageImage(image[0].groupId, image[0].file_name, company_id)
      ) {
        try {
          await app.db
            .ref(`groups/${company_id}/${image[0].groupId}/images/${imageID}`)
            .remove();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const deleteStorageImage = async (groupID, filename, companyID) => {
    //deletes the firestore image

    try {
      let response = await app.storage
        .ref()
        .child(`${companyID}/${groupID}/${filename}`)
        .delete();
      console.log(response);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const loadImagesByGroupID = async (groupId, company_id) => {
    let tmpGroupImages = [];

    try {
      let ref = await app.db.ref(`groups/${company_id}/${groupId}/images`);
      ref
        .orderByChild('groupId')
        .equalTo(groupId)
        .on('value', (snapshot) => {
          // tmpGroups = snapshot.val();
          if (snapshot.val() !== null) {
            tmpGroupImages = Object.entries(snapshot.val()).map((item) => {
              return { id: item[0], ...item[1] };
            });
            dispatch({ type: LOAD_GROUP_IMAGES, payload: tmpGroupImages });
          } else {
            dispatch({ type: CLEAR_GROUP_IMAGES });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateImage = async (data) => {
    //This will update the image record
    let updates = {};
    const imageID = data.id;
    delete data.id;
    updates[`groups/${data.company_id}/${data.groupId}/images/${imageID}`] = {
      ...data
    };
    try {
      app.db.ref().update(updates);
    } catch (error) {
      console.log(error);
    }
  };

  const scaleFile = (file) => {
    let newFileName =
      file.name === 'image.jpg'
        ? Math.round(Math.random() * 10000000000 + 10000000) + '.jpg'
        : file.name;

    console.log(newFileName);
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.7,
        height: 1800,

        success(result) {
          const newFile = new File([result], newFileName, {
            type: 'image/jpeg'
          });
          resolve(newFile);
        },
        error(err) {
          console.log(err.message);
          reject(err);
        }
      });
    });
  };

  const uploadImage = async (groupId, file, company_id) => {
    let newFile = await scaleFile(file);
    console.log(newFile);
    let uploadTask = app.storage
      .ref()
      .child(`${company_id}/${groupId}/${newFile.name}`)
      .put(newFile);

    uploadTask.progress = 0;
    uploadTask.file_name = newFile.name;
    uploadTask.groupId = groupId;
    uploadTask.downloadURL = uploadIMG;
    uploadTask.company_id = company_id;
    registerUploadTask(uploadTask);
    dispatch({
      type: UPLOAD_IMAGE_START,
      payload: uploadTask
    });
  };

  const registerUploadTask = (uploadTask) => {
    let image = {};
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadTask.progress = `${progress.toFixed(1)}%`;
        dispatch({ type: UPLOAD_IMAGE_UPDATE });
        // console.log('Upload is ' + uploadTask.progress + 'done');
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            // console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            // console.log('Upload is running');
            break;
          default:
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        // let image = {};

        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
          image.downloadURL = downloadURL;
          image.uid = state.authUser.uid;
          image.groupId = uploadTask.groupId;
          image.file_name = uploadTask.file_name;
          image.company_id = uploadTask.company_id;
          image.image_type = 1;
          uploadTask.downloadURL = downloadURL;

          createImage(image);
          dispatch({ type: UPLOAD_IMAGE_COMPLETE, payload: downloadURL });
        });
      }
    );
  };

  const checkInviteExists = async (companyID, inviteID) => {
    let snapshot = await app.db
      .ref(`inviteusers/${companyID}/users/${inviteID}`)
      .once('value');
    return snapshot.exists();
  };

  const checkInvite = async (companyID, inviteID) => {
    let snapshot = await app.db
      .ref(`inviteusers/${companyID}/users/${inviteID}`)
      .once('value');
    if (snapshot.exists()) {
      return snapshot.val().sign_up_complete;
    }
  };

  const setInviteFlag = async (companyID, inviteID, inviteFlag) => {
    const updates = {};
    try {
      updates[`inviteusers/${companyID}/users/${inviteID}/sign_up_complete`] =
        inviteFlag;
      app.db.ref().update(updates);
    } catch (error) {
      console.log(error);
    }
  };
  const inviteUser = async (user) => {
    const updates = {};
    try {
      const newInvite = await app.db
        .ref()
        .child(`inviteusers/${user.company_id}/users`);
      let inviteID = newInvite.push().key;

      updates[inviteID] = { ...user, sign_up_complete: false };
      newInvite.update(updates);
      return inviteID;
    } catch (err) {
      console.log(err);
    }
  };

  const deleteInvitedUser = async (company_id, inviteID) => {
    try {
      await app.db.ref(`inviteusers/${company_id}/users/${inviteID}`).remove();
    } catch (error) {
      console.log(error);
    }
  };

  const loadInvitedUsers = async (companyID) => {
    let invitedUsers = [];

    try {
      await app.db
        .ref(`inviteusers/${companyID}/users`)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            invitedUsers = Object.entries(snapshot.val()).map((user) => {
              return { id: user[0], ...user[1] };
            });
            dispatch({ type: LOAD_INVITED_USERS, payload: invitedUsers });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        authUser: state.authUser,
        isAuthenticated: state.isAuthenticated,
        isAccountManager: state.isAccountManager,
        user: state.user,
        users: state.users,
        error: state.error,
        groups: state.groups,
        imageUploads: state.imageUploads,
        updateTick: state.updateTick,
        groupImages: state.groupImages,
        loading: state.loading,
        companyName: state.company_name,
        companyID: state.company_id,
        invitedUsers: state.invitedUsers,
        submittedGroupFilter: state.submittedGroupFilter,
        loadImagesByGroupID,
        authenticateUser,
        signOut,
        createUser,
        getUser,
        loadCompany,
        loadUsers,
        uploadImage,
        updateImage,
        deleteImage,
        clearErrors,
        createGroup,
        clearGroupImages,
        unsubscribeFromGroupImages,
        loadGroupsByUserID,
        updateGroup,
        registerCompany,
        inviteUser,
        checkInvite,
        checkInviteExists,
        loadInvitedUsers,
        submitGroup,
        setSubmittedGroupFilter,
        resetPassword,
        deleteInvitedUser
      }}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseState;
