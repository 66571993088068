import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import firebaseContext from '../../context/firebaseContext/firebaseContext';
import AlertContext from '../../context/alertContext/alertContext';

const Login = (props) => {
  const {
    authenticateUser,
    isAuthenticated,
    isAccountManager,
    error,
    clearErrors,
    user,
    loading
  } = useContext(firebaseContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [inputValues, setInputValues] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    M.updateTextFields();

    if (isAuthenticated && !loading) {
      if (isAccountManager) {
        props.history.push('/AdminDashboard');
      } else {
        props.history.push('/userdashboard');
      }
    }

    if (error) {
      console.log(error);
      setAlert('Wrong Username or Password', 'error');
      clearErrors();
    }
  }, [isAuthenticated, error]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    authenticateUser(inputValues.email, inputValues.password);
  };

  return (
    <Fragment>
      <div className="row center-align">
        <div className="section"></div>

        <h5 className="indigo-text center-align">
          Please, login into your account
        </h5>
        <div className="section"></div>
        <div
          className="z-depth-2 row grey lighten-5"
          style={{ display: 'inline-block', padding: '32px 38px 0px 38px' }}>
          <form onSubmit={handleOnSubmit} className="col s12 ">
            <div className="row">
              <div className="input-field col s12">
                <input
                  placeholder="enter email"
                  type="email"
                  id="email"
                  className="validate"
                  name="email"
                  value={inputValues.email}
                  onChange={handleOnChange}
                />
                <label htmlFor="email">Email</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="password"
                  id="password"
                  placeholder="enter password"
                  className="validate"
                  name="password"
                  value={inputValues.password}
                  onChange={handleOnChange}
                />
                <label htmlFor="password">Password</label>
              </div>
              <label className="right-align col s12">
                <Link to="/ResetPassword" className="pink-text">
                  Forgot Password?
                </Link>
              </label>
            </div>
            <div className="row">
              <button
                type="submit"
                name="btn_login"
                className="col s12 btn btn-large waves-effect indigo">
                Login
              </button>
            </div>
          </form>
        </div>
        {/* <div className="row">
          <Link to="/register">Create Account</Link>
        </div> */}
      </div>
    </Fragment>
  );
};
export default Login;
