import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';
import '../../extended-styles.css';
import { format } from 'date-fns';

const UserDashboard = (props) => {
  const firebaseContext = useContext(FirebaseContext);
  const {
    createGroup,
    authUser,
    groups,
    setSubmittedGroupFilter,
    submittedGroupFilter,
    loadGroupsByUserID
  } = firebaseContext;

  const [groupsLoaded, setGroupsLoaded] = useState(false);
  const [intialLoad, setIntialLoad] = useState(false);

  useEffect(() => {
    refreshState(false);
    loadGroupsByUserID();
  }, [submittedGroupFilter]);

  const refreshState = (s) => {
    if (!intialLoad) {
      setSubmittedGroupFilter(s);
      setIntialLoad(true);
    }
  };
  const createNewGroup = () => {
    // createGroup(authUser.uid, '201578-1');
    props.history.push('/newgroup');
  };

  const handleSubmitFilterOn = () => {
    setSubmittedGroupFilter(!submittedGroupFilter);
    // loadGroupsByUserID();
  };

  const getDate = (timestamp) => {
    return format(new Date(timestamp), 'MM/dd/yyyy');
  };

  const handleWorkOrderOnClick = (e) => {
    let groupID = e.currentTarget.id;
    props.history.push(`/groupdetail/${groupID}`);
  };
  const getListItem = (group) => {
    return (
      <tr
        className="list-item-font"
        key={group.id}
        id={group.id}
        onClick={handleWorkOrderOnClick}>
        <td>{group.group_name}</td>
        <td>{group.property_name}</td>
        <td>
          {group.submitted_timestamp !== null &&
          group.hasOwnProperty('submitted_timestamp')
            ? getDate(group.submitted_timestamp)
            : 'Not Submitted'}
        </td>
      </tr>
    );
  };

  const getGroupListHeader = () => {
    return (
      <thead>
        <tr className="general-font">
          <th>Work Order</th>
          <th>Property</th>
          <th>Submitted</th>
        </tr>
      </thead>
    );
  };
  return (
    <div className="flex-container-col">
      <div className="row valign-wrapper group-header">
        <div className="col s6">
          <h3>Groups</h3>
        </div>
        <div className="col s6">
          <div className="switch-wrapper">
            <div className="switch switch-padding ">
              <label>
                Submitted
                <input
                  type="checkbox"
                  value={submittedGroupFilter}
                  onChange={handleSubmitFilterOn}
                />
                <span className="lever"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="table-container scroll-overflow-group">
        <table>
          {getGroupListHeader()}
          <tbody>
            {groups !== null ? (
              groups.map((group) => getListItem(group))
            ) : (
              <tr>
                <td>NO GROUPS</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row width-100">
        <button className="btn indigo width-100" onClick={createNewGroup}>
          Add Group
        </button>
      </div>
    </div>
  );
};

export default UserDashboard;
