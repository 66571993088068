import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { Fragment, useEffect } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Landing from './components/landing/Landing';
import Login from './components/auth/Login';
import Navbar from './components/layout/Navbar';
import Register from './components/auth/Register';
import RegisterUser from './components/auth/RegisterUser';
import AdminDashboard from './components/adminDashboard/AdminDashboard';
import UserDashboard from './components/userDashboard/UserDashboard';
import PrivateRoute from './components/routing/PrivateRoute';
import AlertState from './context/alertContext/AlertState';
import Alerts from './components/layout/Alerts';
import NewGroup from './components/groups/NewGroup';
import GroupDetail from './components/groupDetail/GroupDetail';
import ImageDetail from './components/imageDetail/ImageDetail';
import UserInvite from './components/userInvite/UserInvite';
import ResetPassword from './components/auth/ResetPassword';

import './app.css';

import FirebaseState from '../src/context/firebaseContext/FirebaseState';
function App() {
  useEffect(() => {
    //Initialize the Materialize Javascript
    M.AutoInit();
  });

  return (
    <FirebaseState>
      <AlertState>
        <BrowserRouter>
          <Navbar />
          <Alerts />
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route exact path="/Login" component={Login} />
            {/* <Route exact path="/Register" component={Register} /> */}
            <Route
              exact
              path="/RegisterUser/:companyID"
              component={RegisterUser}
            />
            <Route exact path="/ResetPassword" component={ResetPassword} />
            <PrivateRoute
              exact
              path="/AdminDashboard"
              component={AdminDashboard}
            />
            <PrivateRoute
              exact
              path="/userdashboard"
              component={UserDashboard}
            />
            <PrivateRoute exact path="/newgroup" component={NewGroup} />
            <PrivateRoute
              exact
              path="/groupdetail/:groupid"
              component={GroupDetail}
            />
            <PrivateRoute
              exact
              path="/imagedetail/:imageid"
              component={ImageDetail}
            />
            <PrivateRoute exact path="/userinvite" component={UserInvite} />
          </Switch>
        </BrowserRouter>
      </AlertState>
    </FirebaseState>
  );
}

export default App;
