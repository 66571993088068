import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const firebaseContext = useContext(FirebaseContext);
  const { isAuthenticated, loading } = firebaseContext;

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated || loading ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
          // <Redirect to="/login" />
        )
      }
    />
  );
};
export default PrivateRoute;
