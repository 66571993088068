import React, { Fragment, useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import firebaseContext from '../../context/firebaseContext/firebaseContext';
import AlertContext from '../../context/alertContext/alertContext';

const ResetPassword = () => {
  let history = useHistory();

  const { resetPassword } = useContext(firebaseContext);

  const [inputValues, setInputValues] = useState({
    email: ''
  });

  useEffect(() => {
    M.updateTextFields();
  });

  const handleOnSubmit = async () => {
    try {
      await resetPassword(inputValues.email);
      history.push('/login');
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  return (
    <Fragment>
      <div className="row center-align">
        <div className="section"></div>

        <h4 className="indigo-text center-align">Forgot Password</h4>
        <div className="section"></div>
        <div
          className="z-depth-2 row grey lighten-5"
          style={{
            display: 'inline-block',
            padding: '22px 20px 0px 20px',
            width: '300px'
          }}>
          <p className="forgot-msg-text center">
            Enter your account email address to receive a link to reset your
            password.
          </p>
          <form onSubmit={handleOnSubmit} className="col s12 ">
            <div className="row">
              <div className="input-field col s12">
                <input
                  placeholder="enter email"
                  type="email"
                  id="email"
                  className="validate"
                  name="email"
                  value={inputValues.email}
                  onChange={handleOnChange}
                />
                <label htmlFor="email">Email</label>
              </div>
            </div>
            <div className="row">
              <button
                type="submit"
                name="btn_login"
                className="col s12 btn btn-large waves-effect indigo">
                Send Reset Link
              </button>
            </div>
          </form>
        </div>
        <div className="row">
          <Link to="/register">Create Account</Link>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
