import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';

const Navbar = () => {
  const { isAuthenticated, signOut } = useContext(FirebaseContext);

  const history = useHistory();
  const onLogout = () => {
    signOut();
  };

  const [showBackButton, setShowBackButton] = useState(false);

  useEffect(() => {
    checkBackState();
  });

  const checkBackState = () => {
    if (
      history.location.pathname !== '/userdashboard' &&
      history.location.pathname !== '/login'
    ) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  };
  const handleGoBack = () => {
    history.goBack();

    setTimeout(() => {
      checkBackState();
    }, 25);
  };

  const navLinks = (
    <Fragment>
      {/* <li>
        <Link to="/register">Register</Link>
      </li> */}
      <li>
        <Link to="/login">Login</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
    </Fragment>
  );

  const authLinks = (
    <Fragment>
      <li>
        <Link to="/userdashboard">Groups</Link>
      </li>
      <li>
        <a onClick={onLogout} href="#!">
          <span className="hide-sm">Logout</span>
        </a>
      </li>
    </Fragment>
  );

  return (
    <Fragment>
      <nav className="indigo">
        <div className="nav-wrapper">
          <div className="flex-container flex-nav-align">
            {showBackButton ? (
              <div className="flex-1">
                <div href="#!" onClick={handleGoBack} className="icon-padding">
                  <i className="material-icons">arrow_back</i>
                </div>
              </div>
            ) : null}
            <div className="flex-1">
              <a href="#!" className="brand-logo">
                <i className="material-icons">party_mode</i>
                PIC Sender
              </a>
            </div>
            <div className="flex-1 hide-on-large-only">
              <a
                href="#!"
                data-target="mobile-demo"
                className="right sidenav-trigger ">
                <i className="material-icons">menu</i>
              </a>
            </div>
            <div className="hide-on-med-and-down">
              <ul className="hide-on-med-and-down">
                {isAuthenticated ? authLinks : navLinks}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-demo">
        {isAuthenticated ? authLinks : navLinks}
        <li>Build 21.06.29-1</li>
      </ul>
    </Fragment>
  );
};

export default Navbar;
