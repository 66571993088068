import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';
import { generateInviteLink } from '../../utils/helper';

const UserInvite = () => {
  const firebaseContext = useContext(FirebaseContext);
  const { inviteUser, companyID } = firebaseContext;
  const history = useHistory();
  const [inputValues, setInputValues] = useState({
    email: '',
    user_name: ''
  });

  const handleFieldUpdate = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
    });
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    inputValues.company_id = companyID;
    let inviteCode = await inviteUser(inputValues);
    history.push('/admindashboard');
    //send email with the invite link
  };

  return (
    <div className="container">
      <h1>Invite User</h1>
      <div className="row">
        <div className="col s12">
          <form onSubmit={handleFormSubmit} className="col s12">
            <div className="row">
              <input
                type="email"
                name="email"
                placeholder="Enter Email Address"
                onChange={handleFieldUpdate}
                value={inputValues.email}
              />
            </div>
            <div className="row">
              <input
                type="text"
                name="user_name"
                placeholder="Enter Name"
                onChange={handleFieldUpdate}
                value={inputValues.user_name}
              />
            </div>
            <div className="row">
              <button
                className="col s12 indigo btn waves-effect waves-light"
                type="submit"
                name="btn_submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserInvite;
