import React, { useContext, useEffect, useState } from 'react';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';
import '../../extended-styles.css';
import M from 'materialize-css/dist/js/materialize.min.js';

const ImageDetail = ({ history, match }) => {
  const firebaseContext = useContext(FirebaseContext);
  const { groupImages, updateImage } = firebaseContext;

  const [updateMode, setUpdateMode] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false);
  const [objImage, setObjImage] = useState({});
  const [displaySave, setDisplaySave] = useState(false);

  useEffect(() => {
    let elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);
    if (!initialLoad) {
      setImage(match.params.imageid);
    }
    // console.log(objImage);
  }, [objImage]);

  const setImage = (imageid) => {
    let obj = {};

    const selectedImage = groupImages.filter((image) => {
      return image.id === imageid;
    });
    // console.log(selectedImage[0]);
    obj = { ...selectedImage[0] };
    obj.note = selectedImage[0].note || '';
    obj.image_type = selectedImage[0].image_type || 1;
    // obj.imageId = selectedImage[0].id;
    // obj.groupId = selectedImage[0].groupId;
    // obj.imageURL = selectedImage[0].downloadURL;
    setObjImage({ ...obj });
    setInitialLoad(true);
  };

  const handleToggleNote = () => {
    setUpdateMode(!updateMode);
  };

  const handleNoteOnChange = (e) => {
    setObjImage({ ...objImage, note: e.target.value });
    setDisplaySave(true);
    // setNoteBuffer(e.target.value);
  };

  const updateImageNote = () => {
    updateImage({ ...objImage });
    setDisplaySave(false);
    setUpdateMode(false);
  };

  const handleImageTypeOnChange = (e) => {
    setObjImage({ ...objImage, image_type: e.target.value });
    setDisplaySave(true);
  };

  return (
    <div className="container" style={{ marginTop: '25px' }}>
      <div className="col s12">
        <div className="input-field col s12">
          <select
            value={objImage.image_type}
            onChange={handleImageTypeOnChange}>
            <option value="1">Work Order Image</option>
            <option value="2">Incomplete Image</option>
          </select>
          <label>Select Image Type</label>
        </div>
      </div>
      <div className="col s12 m8 offset-m2">
        <div className="card">
          <div className="card-image">
            <img src={objImage.downloadURL} />
            <span className="card-title">{objImage.file_name}</span>
            <a
              className="btn-floating halfway-fab waves-effect waves-light red"
              onClick={handleToggleNote}>
              <i className="material-icons">create</i>
            </a>
          </div>
          <div className="card-content">
            <Note
              text={objImage.note}
              handleChange={handleNoteOnChange}
              updateModeState={updateMode}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {displaySave ? (
          <button className="btn col s12" onClick={updateImageNote}>
            Save
          </button>
        ) : null}
      </div>
    </div>
  );
};

const Note = ({ text, handleChange, updateModeState }) => {
  const block = updateModeState ? (
    <textarea
      className="general-font general-text-padding text-box"
      name="note"
      id="note"
      cols="30"
      row="50"
      value={text}
      onChange={handleChange}></textarea>
  ) : (
    <p className="general-font">{text}</p>
  );

  return block;
};
export default ImageDetail;
