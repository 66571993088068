import React, { useContext } from 'react';
import AlertContext from '../../context/alertContext/alertContext';
const Alerts = () => {
  const alertContext = useContext(AlertContext);

  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map((alert) => {
      return (
        <div
          key={alert.id}
          className={`card-panel orange valign-wrapper darken-2`}>
          <i className="material-icons">{alert.type}</i>
          {alert.msg}
        </div>
      );
    })
  );
};
export default Alerts;
