import React, { Fragment, useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';
import AlertContext from '../../context/alertContext/alertContext';
import M from 'materialize-css/dist/js/materialize.min.js';

const RegisterUser = ({ history, match }) => {
  const firebaseContext = useContext(FirebaseContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const {
    createUser,
    loadCompany,
    companyName,
    checkInvite,
    checkInviteExists
  } = firebaseContext;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  const [inputValues, setInputValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password1: '',
    password2: '',
    isAccountOwner: false,
    isAccountManager: false,
    company_id: match.params.companyID,
    invite_id: query.get('invite')
  });

  useEffect(() => {
    M.updateTextFields();
    loadCompanyName();
    isInviteValid();
  }, []);

  const isInviteValid = async () => {
    let companyID = inputValues.company_id;
    let inviteID = inputValues.invite_id;
    let inviteExists = await checkInviteExists(companyID, inviteID);
    if (inviteID === '' || inviteID === null || !inviteExists) {
      setAlert(
        'Your Invite Has Expired, please ask your account manager to send another one.'
      );

      setTimeout(() => {
        history.push('/login');
      }, 5000);
    }

    let complete = await checkInvite(
      inputValues.company_id,
      inputValues.invite_id
    );
    if (complete) {
      history.push('/login');
    }
  };
  const loadCompanyName = () => {
    if (!companyName) {
      loadCompany(match.params.companyID);
    }
  };
  const updateValues = (e) => {
    const { id, value } = e.target;
    setInputValues({ ...inputValues, [id]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const newUser = { ...inputValues };
    createUser(newUser);
    history.push('/userdashboard');
  };

  return (
    <Fragment>
      <h3 className="indigo-text center-align">Create Account</h3>
      <h4 className="indigo-text center-align">{companyName}</h4>
      <div className="section"></div>
      <div className="container">
        <div className="row">
          <form onSubmit={handleFormSubmit} className="col s12">
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="text"
                  placeholder="Enter Your First Name"
                  id="first_name"
                  onChange={updateValues}
                  value={inputValues.first_name}
                />
                <label htmlFor="first_name">First Name</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="text"
                  placeholder="Enter Your Last Name"
                  id="last_name"
                  onChange={updateValues}
                  value={inputValues.last_name}
                />
                <label htmlFor="last_name">Last Name</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="email"
                  placeholder="Enter Your Email Address"
                  id="email"
                  className="validate"
                  onChange={updateValues}
                  value={inputValues.email}
                />
                <label htmlFor="email">Email</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="password"
                  placeholder="Enter Your Password"
                  id="password1"
                  name="password1"
                  className="validate"
                  onChange={updateValues}
                  value={inputValues.password1}
                />
                <label htmlFor="password1">Password</label>
              </div>
            </div>
            <div className="row">
              <div className="input-field col s12">
                <input
                  type="password"
                  placeholder="Verify Your Password"
                  id="password2"
                  name="password2"
                  className="validate"
                  onChange={updateValues}
                  value={inputValues.password2}
                />
                <label htmlFor="password2">Verify Password</label>
              </div>
            </div>
            <div className="row">
              <button
                type="submit"
                name="btn_submit"
                className="col s12 btn btn-large waves-effect indigo">
                Create Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default RegisterUser;
