import {
  AUTH_USER,
  AUTH_ERROR,
  AUTH_REGISTER_COMPANY,
  AUTH_REGISTER_USER,
  CLEAR_ERRORS,
  CLEAR_GROUP_IMAGES,
  GET_USER,
  GET_USER_FAIL,
  SET_LOADING,
  SIGN_OUT,
  LOAD_COMPANY,
  LOAD_COMPANY_FAILED,
  LOAD_USERS,
  LOAD_USERS_FAIL,
  LOAD_GROUPS,
  LOAD_INVITED_USERS,
  LOAD_INVITED_USERS_FAILED,
  UPLOAD_IMAGE_START,
  UPLOAD_IMAGE_UPDATE,
  UPLOAD_IMAGE_COMPLETE,
  LOAD_GROUP_IMAGES,
  DELETE_IMAGE,
  GROUP_FILTER
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authUser: action.payload,
        isAuthenticated: action.payload !== null
      };
    case AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        isAccountManager: false,
        user: null,
        loading: false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: ''
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        isAccountManager: action.payload.isAccountManager
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case GET_USER_FAIL:
      return {
        ...state,
        user: null,
        loading: false
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    case SIGN_OUT:
      return {
        ...state,
        loading: false,
        authUser: null,
        isAuthenticated: false,
        isAccountManager: false,
        user: null,
        groups: null
      };
    case LOAD_COMPANY:
      return {
        ...state,
        company_name: action.payload.company_name,
        company_id: action.payload.company_id
      };
    case LOAD_COMPANY_FAILED:
      return {
        ...state,
        company_name: null,
        company_id: null
      };
    case LOAD_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload
      };
    case LOAD_USERS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case LOAD_GROUPS:
      return {
        ...state,
        groups: action.payload
      };
    case LOAD_INVITED_USERS:
      return {
        ...state,
        invitedUsers: action.payload
      };
    case UPLOAD_IMAGE_START:
      return {
        ...state,
        imageUploads: [...state.imageUploads, action.payload]
      };
    case UPLOAD_IMAGE_UPDATE:
      return {
        ...state,
        updateTick: state.updateTick++
      };
    case LOAD_GROUP_IMAGES:
      return {
        ...state,
        groupImages: action.payload
      };
    case CLEAR_GROUP_IMAGES:
      return {
        ...state,
        groupImages: []
      };
    case UPLOAD_IMAGE_COMPLETE:
      let newArray = state.imageUploads.filter(
        (item) => item.downloadURL !== action.payload
      );
      return {
        ...state,
        imageUploads: newArray
      };
    case GROUP_FILTER:
      return {
        ...state,
        submittedGroupFilter: action.payload
      };
    default:
      return state;
  }
};
