import React, { useState, useEffect, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';
import AlertContext from '../../context/alertContext/alertContext';

const NewGroup = (props) => {
  const firebaseContext = useContext(FirebaseContext);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const { createGroup, authUser, user } = firebaseContext;
  const [inputValues, setInputValues] = useState({
    group_name: '',
    property_name: ''
  });
  const updateValues = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };
  useEffect(() => {
    M.updateTextFields();
  });
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (inputValues.group_name) {
      const group = { ...inputValues };
      createGroup(authUser.uid, group, user.company_id);
      props.history.push('/userdashboard');
    } else {
      setAlert('Please Enter Group Name', 'error');
    }
  };
  return (
    <Fragment>
      <h5 className="indigo-text center-align">Add New Group</h5>
      <div className="section">
        <div className="container">
          <div className="row">
            <form onSubmit={handleFormSubmit} className="col s12">
              <div className="row">
                <div className="input-field col s12">
                  <input
                    name="group_name"
                    type="text"
                    placeholder="Enter Group Name"
                    value={inputValues.group_name}
                    onChange={updateValues}
                  />
                  <label htmlFor="group_name">Group Name</label>
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <input
                    name="property_name"
                    type="text"
                    placeholder="Enter Propery Name"
                    value={inputValues.property_name}
                    onChange={updateValues}
                  />
                  <label htmlFor="property_name">Property Name</label>
                </div>
              </div>
              <div className="row">
                <button
                  type="submit"
                  name="btn_submit"
                  className="col s12 btn btn-large indigo">
                  Submit
                </button>
              </div>
              <div className="row">
                <Link
                  to="/userdashboard"
                  className="col s12 btn btn-large red lighten-2">
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewGroup;
