export const AUTH_USER = 'AUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_REGISTER_COMPANY = 'AUTH_REGISTER_COMPANY';
export const AUTH_REGISTER_USER = 'AUTH_REGISTER_USER';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const SET_LOADING = 'SET_LOADING';
export const SIGN_OUT = 'SIGN_OUT';

export const LOAD_COMPANY = 'LOAD_COMPANY';
export const LOAD_COMPANY_FAILED = 'LOAD_COMPANY_FAILED';
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_FAIL = 'LOAD_USERS_FAIL';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_INVITED_USERS = 'LOAD_INVITED_USERS';
export const LOAD_INVITED_USERS_FAILED = 'LOAD_INVITED_USERS_FAILED ';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILED = 'UPLOAD_IMAGE_FAILED';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CREATE_GROUP = 'CREATE_GROUP ';
export const GET_GROUPS = 'GET_GROUPS';
export const UPLOAD_IMAGE_START = 'UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_UPDATE = 'UPLOAD_IMAGE_UPDATE';
export const LOAD_GROUP_IMAGES = 'LOAD_GROUP_IMAGES';
export const CLEAR_GROUP_IMAGES = 'CLEAR_GROUP_IMAGES';
export const UPLOAD_IMAGE_COMPLETE = 'UPLOAD_IMAGE_COMPLETE ';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const GROUP_FILTER = 'GROUP_FILTER';
