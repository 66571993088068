import React, { useState } from 'react';
import styles from './Styles.module.css';
import UserList from '../usersList/UserList';
import CompanyInfo from '../companyInfo/CompanyInfo';

const AdminDashboard = () => {
  // const nav = `collection-item ${styles.hover_green} `;
  const nav = `collection-item`;
  const [currentView, setCurrentView] = useState(1);

  const getView = () => {
    switch (currentView) {
      case 1:
        return <UserList />;
      case 2:
        return <CompanyInfo />;
      default:
        break;
    }
  };

  const onClickUser = (viewID) => {
    setCurrentView(viewID);
  };

  return (
    <div className="row">
      <div className="col s2 grey lighten-2" style={{ padding: 0 }}>
        <div className={styles.left_nav}>
          <ul
            className="collection with-header "
            style={{ width: '100%', margin: '0.1rem', color: 'grey' }}>
            <li className="collection-header ">
              <h4>Admin</h4>
            </li>
            <li className={nav} onClick={() => onClickUser(1)}>
              Users
            </li>
            <li className={nav} onClick={() => onClickUser(2)}>
              Company Info
            </li>
            <li className={nav}>Billing Info</li>
          </ul>
        </div>
      </div>

      <div className="col s10">{getView()}</div>
    </div>
  );
};

export default AdminDashboard;
