import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import FirebaseContext from '../../context/firebaseContext/firebaseContext';

const UserList = () => {
  const firebaseContext = useContext(FirebaseContext);
  const {
    loadCompany,
    loadUsers,
    user,
    users,
    loadInvitedUsers,
    invitedUsers,
    deleteInvitedUser
  } = firebaseContext;

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [pageInitialized, setPageInitialized] = useState(false);
  const [selectedInvitedUser, setSelectedInvitedUser] = useState(null);

  useEffect(() => {
    initializePage();
    refreshList();
  }, [invitedUsers]);

  const initializePage = () => {
    if (!pageInitialized) {
      console.log('initializing page');
      loadUsers(user.company_id);
      loadCompany(user.company_id);
      loadInvitedList();
      setPageInitialized(true);
    }
  };

  const handleDeleteInviationClick = () => {
    deleteInvitedUser(user.company_id, selectedInvitedUser);
    setSelectedInvitedUser(null);
  };

  const handleSelectInvitedUser = (e) => {
    setSelectedInvitedUser(e.target.id);
  };

  const refreshList = () => {
    if (pageInitialized) {
      filterUsers(false);
    }
  };
  const history = useHistory();

  const filterUsers = (isSignUpComplete) => {
    if (invitedUsers) {
      const tmpUsers = invitedUsers.filter((u) => {
        return u.sign_up_complete === isSignUpComplete;
      });
      setFilteredUsers((arr) => tmpUsers);
    }
  };

  const loadInvitedList = async () => {
    await loadInvitedUsers(user.company_id);
    filterUsers(false);
  };

  const handleOnClickInviteUser = () => {
    history.push('/userinvite');
  };

  return (
    <div className="container">
      <h4>User List</h4>
      <div className="row">
        <div className="col s12 m6">
          {users !== null && users.length > 0 ? (
            <CurrentUsers Users={users} />
          ) : (
            <div>No Users</div>
          )}
        </div>
        <div className="col s12 m6">
          {filteredUsers !== null && filteredUsers.length > 0 ? (
            <InvitedUsers
              Users={filteredUsers}
              handleOnClick={handleSelectInvitedUser}
              selectedInvitedUser={selectedInvitedUser}
            />
          ) : (
            <div>No Users</div>
          )}
          <button
            className="btn-large indigo col s12 m12 waves-effect waves-light"
            onClick={handleOnClickInviteUser}>
            Invite User
          </button>

          <button
            style={{ marginTop: '4px' }}
            className="btn-large red lighten-2 col s12 m12 waves-effect waves-light"
            onClick={handleDeleteInviationClick}>
            Delete Invitation
          </button>
        </div>
      </div>
    </div>
  );
};

const CurrentUsers = ({ Users }) => {
  const userItems = Users.map((user) => (
    <div key={user.id} className="collection-item">
      {user.first_name} {user.last_name}
    </div>
  ));

  return (
    <div
      className="collection with-header"
      style={{ overflow: 'scroll', height: 500 }}>
      <div className="collection-header indigo lighten-4">
        <h4>Users</h4>
      </div>
      {userItems}
    </div>
  );
};

const InvitedUsers = ({ Users, handleOnClick, selectedInvitedUser }) => {
  const userItems = Users.map((user) => {
    let selectClasses = ['collection-item'];
    if (user.id === selectedInvitedUser) {
      selectClasses.push('active');
    }
    return (
      <div
        key={user.id}
        id={user.id}
        className={selectClasses.join(' ')}
        onClick={handleOnClick}>
        {user.user_name}
      </div>
    );
  });

  return (
    <div
      className="collection with-header"
      style={{ overflow: 'scroll', height: 500 }}>
      <div className="collection-header indigo lighten-4">
        <h4>Invited Users</h4>
      </div>
      {userItems}
    </div>
  );
};
export default UserList;
